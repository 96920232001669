<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>

    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ radioAnswer }}</div>

    <div flex-row-flex-start-flex-start class="tag-spacing" v-if="combineAnswer">
      <span class="component-label">{{ combineName }}：</span>
      <div flex-row-flex-start-center gap10>
        <span>{{ combineAnswer }}</span>
      </div>
    </div>

    <!--    <c-pre>{{ combineAnswer }}</c-pre>-->
    <!--    <c-pre>{{ computedWidgetCustomContent }}</c-pre>-->
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'
import {isEmpty}      from 'lodash'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtRadioCombineInput',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {
      pcaText      : '',
      combineName  : '军官证编号',
      combineAnswer: '',
      radioAnswer  : '',
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {},
  methods : {},
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {
    if (isEmpty(this.widget)) {throw new Error('widget is empty')}
    if (this.widget && Reflect.has(this.widget, 'answer') && this.widget.answer) {
      let find = this.widget.optionList.find(item => item.optionValue == this.widget.answer)
      if (!isEmpty(find)) {
        this.radioAnswer = find.optionName
      }
      ///
      let combineAnswer = this.widget.combineComponent.combineAnswer
      if (combineAnswer) {
        this.combineAnswer = combineAnswer
      }
    }
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss"> @import "component.scss";
@import "component.scss";
</style>
